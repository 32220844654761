import { useRoute } from '#imports'
import { computed } from 'vue'

import { landingReviewsAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

const { getRating, getReviews } = landingReviewsAPI

export function useProductListRating(id: string) {
  return useHttpFetch(getRating, {
    pathParams: { uuid: id },
  })
}
export function useProductListReviews(id: string) {
  const route = useRoute()

  const queryParams = computed(() => route.query)

  return useHttpFetch(getReviews, {
    pathParams: { uuid: id },
    queryParams,
  })
}
