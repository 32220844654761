<template>
  <div v-if="rates?.count">
    <ReviewsBlock
      :link="{
        name: REVIEWS_ROUTES.PAGES.LANDING,
        params: {
          uuid: id,
          slugV2: slug,
        },
      }"
      :product-title="title"
      :rating="rates"
      :reviews="reviews?.results || []"
      :reviews-pending="pendingReviews"
    />
  </div>
</template>

<script setup lang="ts">
import ReviewsBlock from '~/scopes/reviews/reviews-display/components/ReviewsBlock/ReviewsBlock.vue'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import {
  useProductListRating,
  useProductListReviews,
} from '../composables/useProductListReviews'

const props = defineProps<{
  title: string
  id: string
  slug: string
}>()

const { data: rates } = useProductListRating(props.id)
const { data: reviews, pending: pendingReviews } = useProductListReviews(
  props.id,
)
</script>
