<template>
  <RevContainer v-if="products.length === 0" data-spec="body">
    <NoResult class="my-32" />
  </RevContainer>
  <div v-else>
    <SkinnyBanner page="landing" />
    <Breadcrumb :id="id" />
    <RevContainer class="mb-32">
      <TrustList spot="landing" />
    </RevContainer>
    <RevContainer data-spec="body">
      <div data-spec="main">
        <section class="mb-72" data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-32 lg:flex">
            <div>
              <h1 class="heading-1">{{ title }}</h1>
              <span class="body-2">{{ tagline }}</span>
            </div>

            <SortInput
              v-model="sortInput"
              class="hidden basis-200 lg:block"
              :sort-options="sortOptions"
            />
          </div>

          <div class="lg:flex lg:gap-16">
            <div>
              <SideNavigation class="mb-32" :page-id="id" />
              <div class="hidden lg:block lg:w-256" data-test="filters-wrapper">
                <PriceFilter
                  v-if="priceFacet"
                  v-model="priceInput"
                  :avgPrice="priceFacet.avg"
                  :currency="defaultCurrency"
                  :max="priceFacet.max"
                  :prices="priceFacet.values"
                  :step="priceFacet.step"
                  variant="default"
                />
                <FacetFilter
                  v-for="facet in facets"
                  :key="facet.name"
                  v-model="filtersInput[facet.name]"
                  class="mt-32"
                  :disabled="false"
                  :name="facet.name"
                  :title="facet.title"
                  tracking="product list > side filter"
                  :type="facet.type"
                  :values="facet.values"
                  variant="default"
                />
              </div>
            </div>
            <div v-if="products" class="w-full">
              <ActiveFilters
                v-model:filters="filtersInput"
                v-model:price="priceInput"
                class="mb-8 md:mb-12"
                :facets="facets"
                :max-price="priceFacet?.max ?? 0"
                :search-nb-results="total"
              />
              <ResultGrid
                :article="articles[0]"
                :highlighted-product="products[0]"
                :marketingContent="marketingContent"
                :products="products.slice(1)"
              />
            </div>
          </div>

          <Pagination v-model="pageInput" :page-count="pageCount" />
        </section>

        <Reviews :id="id" class="mb-72" :slug="slug" :title="title" />
        <section class="mb-72">
          <RevSkeleton
            v-if="articlesPending"
            height="439px"
            shape="rectangle"
            width="100%"
          />
          <div v-else-if="articles">
            <h3 class="heading-3 mb-16">
              {{ i18n(translations.buyingGuides) }}
            </h3>

            <ArticleCardsCarousel :articles="articles.slice(1)" />
          </div>
        </section>
        <ProductTags :id="id" class="mb-72" />
        <SEOText class="mb-72" :seo-text="seoText" />
      </div>
    </RevContainer>
    <ActionStickyBar class="lg:hidden" :facets-count="facetCount" />
    <FullscreenFilters
      v-if="displayed"
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets="facets"
      :price-facet="priceFacet"
      :search-nb-results="total"
      :sort-options="sortOptions"
    />
  </div>
</template>

<script lang="ts" setup>
import { createError, useHead, useRoute, useRuntimeConfig } from '#imports'

import { useRouteParams } from '@backmarket/nuxt-layer-base/composables/useRouteParams'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { RevSkeleton } from '@ds/components/Skeleton'

import type { Features } from '~/features'
import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import ArticleCardsCarousel from '~/scopes/cms/shared-components/ArticleCardsCarousel/ArticleCardsCarousel.vue'
import TrustList from '~/scopes/reassurance/components/TrustList/TrustList.vue'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'
import { getMetas } from '~/utils/seo/meta'
import { buildProductSchema, prepareJsonLdScript } from '~/utils/seo/schema'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import ActionStickyBar from '../components/ActionStickyBar.vue'
import ActiveFilters from '../components/ActiveFilters.vue'
import Breadcrumb from '../components/Breadcrumb.vue'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import NoResult from '../components/NoResult.vue'
import Pagination from '../components/Pagination.vue'
import ProductTags from '../components/ProductTags.vue'
import FacetFilter from '../components/Refinements/FacetFilter.vue'
import PriceFilter from '../components/Refinements/PriceFilter.vue'
import ResultGrid from '../components/ResultGrid.vue'
import Reviews from '../components/Reviews.vue'
import SEOText from '../components/SEOText.vue'
import SideNavigation from '../components/SideNavigation.vue'
import SortInput from '../components/SortInput.vue'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'
import { useFiltersFromHash } from '../composables/useHashFilters'
import { useProductListPage } from '../composables/useProductListPage'
import { usePLPArticles } from '../composables/useProductListPageArticles'
import { useProductListRating } from '../composables/useProductListReviews'

import translations from './ProductListPage.translations'

const { uuid: id, slugV2: slug } = useRouteParams()
const { defaultCurrency } = useMarketplace().market

const plpConfiguration = await useProductListPage(id)
if (plpConfiguration.value === null) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Not found',
    fatal: true,
  })
}
const { title, tagline, seoText, marketingContent } = plpConfiguration.value

const searchConfig = await useAlgoliaConfiguration('product-landing', id)

if (searchConfig === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const apiKeyConfig = await useAlgoliaApiKey(searchConfig.indexType)

if (apiKeyConfig === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const sortOptions = getSortOptions(searchConfig.indexes)

const {
  public: { ALGOLIA_ID },
} = useRuntimeConfig()

const {
  total,
  products,
  facets,
  pageCount,
  facetCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
} = await useProductsSearch(ALGOLIA_ID, searchConfig, apiKeyConfig.apiKey)

const { displayed } = useFullscreenFilters()

useFiltersFromHash(filtersInput, priceInput, pageInput)

const { pending: articlesPending, data: articles } = usePLPArticles(id)

const i18n = useI18n()
const route = useRoute()
const locale = useI18nLocale()
const { seoTitle, displayedTitle, links } = plpConfiguration.value
const pageNumber = route.query.p
const metaTitle = pageNumber
  ? i18n(translations.seoTitle, { title: seoTitle, pageNumber })
  : seoTitle
const { data: rates } = await useProductListRating(id)
const metaImageUrl = '/img/plp/relovethepreloved.png'
const { features } = useMarketplace<Features>()
const seoDescription = i18n(translations.metaDescription, {
  coolingOffDays: features.coolingOffDays,
  productType: displayedTitle,
  warrantyDuration: features.warranty.duration,
})

const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
useHead({
  title: metaTitle,
  titleTemplate: `%s | ${COMPANY_NAME}`,
  meta: getMetas({
    title: displayedTitle,
    description: seoDescription,
    image: metaImageUrl,
    'og:type': 'product',
  }),
  link: Object.values(links)
    .map((item) => ({
      rel: 'alternate',
      hreflang: item.params.locale,
      href: item.href,
    }))
    .concat({
      href: route.fullPath,
      hreflang: locale,
      rel: 'canonical',
    }),
  script: [
    prepareJsonLdScript(
      buildProductSchema({
        title: metaTitle,
        description: seoDescription,
        rate: rates.value ?? { averageRate: NaN, count: NaN },
        image: metaImageUrl,
        isOutOfStock: products.value.length === 0,
        priceRange: {
          minPrice: `${priceInput.value[0]}`,
          maxPrice: `${priceInput.value[1]}`,
          currency: defaultCurrency,
        },
        companyName: COMPANY_NAME,
        brand: COMPANY_NAME,
      }),
    ),
  ],
})
</script>
