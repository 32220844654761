<template>
  <div>
    <RevBreadcrumb
      v-if="breadcrumbs.length > 0"
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="breadcrumbs"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type LinkInternal, productListPageAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import translations from './Breadcrumb.translations'

const props = defineProps<{
  id: string
}>()

const i18n = useI18n()

const { data } = await useHttpFetch(productListPageAPI.getBreadcrumb, {
  queryParams: { id: props.id },
})
const Home: {
  title: string
  link: LinkInternal
} = {
  title: i18n(translations.home),
  link: {
    name: 'home',
    hash: {},
    params: {},
    type: 'internal',
    query: {},
  },
}

const breadcrumbs = computed<Array<{ title: string; link: LinkInternal }>>(
  () => (data.value === null ? [] : [Home].concat(data.value)),
)
</script>
